import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { RichTextBlock } from 'prismic-reactjs';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import Button from '@components/button';
import { headerHeight } from '@components/header/header.styled';
import getColorMode, { VariantType } from '@helpers/get-color-mode';

const Container = styled.div<{ paddingTop: string }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 46.75rem;
  margin: 0 auto;
  padding-top: ${({ paddingTop }) => paddingTop || 0};
`;

const StyledButton = styled(Button)`
  margin-top: 3rem;
`;

interface UtilityPageInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: {
        alt: string;
        url: string;
      };
    };
  };
  allPrismicUtilityPage: {
    nodes: {
      data: {
        navbar_color_mode: VariantType['variant'];
        footer_color_mode: VariantType['variant'];
        color_mode: VariantType['variant'];
        page_title: {
          text: string;
        };
        meta_description: {
          text: string;
        };
        meta_image: {
          url: string;
          alt: string;
        };
        title: {
          text: string;
        };
        subheading?: {
          richText: RichTextBlock[];
        };
        link_label?: string;
      };
    }[];
  };
}

interface PageProps {
  data: UtilityPageInterface;
}

const UtilityPage = ({ data }: PageProps): JSX.Element => {
  const {
    allPrismicUtilityPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: UtilityPageInterface = data;

  const {
    page_title,
    meta_description,
    meta_image,
    title,
    subheading,
    link_label,
    navbar_color_mode,
    footer_color_mode,
    color_mode,
  } = allPrismicUtilityPage.nodes[0].data;
  const { backgroundColor, fontColor } = getColorMode(color_mode);
  const isSubheadingPresent: boolean =
    !!subheading?.richText?.length && subheading.richText[0].text !== '';

  const handleClick = () => {
    if (typeof window.history !== 'undefined') {
      window.history.back();
    }
  };

  const ageGate = Cookies.get('age-gate');

  useEffect(() => {
    if (!ageGate) {
      void navigate('/age-gate');
    }
  }, []);

  return (
    <Layout navbarColorMode={navbar_color_mode} footerColorMode={footer_color_mode}>
      <SEO
        title={page_title.text}
        description={meta_description.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <Section backgroundColor={backgroundColor}>
        <Wrapper>
          <Container paddingTop={headerHeight}>
            <Text
              tag="h1"
              fontSize="heading4"
              type="title"
              stringText={title.text}
              fontColor={fontColor}
            />
            {isSubheadingPresent && <Text richText={subheading?.richText} fontColor={fontColor} />}
            {link_label && (
              <StyledButton label={link_label} onClick={handleClick} variant={color_mode} />
            )}
          </Container>
        </Wrapper>
      </Section>
    </Layout>
  );
};

export default UtilityPage;

export const query = graphql`
  query UtilityPageQuery($uid: String) {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    allPrismicUtilityPage(filter: { uid: { eq: $uid } }) {
      nodes {
        ...UtilityPageFragment
      }
    }
  }
`;
